<template>
  <div class="">
    <div class="header flex justify-center items-center">
      <!-- <img v-if="$store.state.config.news_banner" :src="$store.state.config.news_banner" /> -->
      <div class="text-60px font-semibold" style="top: 50%; left: 50%">关于我们</div>
    </div>

    <div class="" style="background-color: #f4f6f9">
      <div class="w-full bg-white">
        <div class="flex justify-between items-start w-1360px m-auto pt-60px pb-200px bg-white">
          <!-- left -->
          <div>
            <div class="text-46px font-semibold w-739px">我们为自己负责，更为客户负责</div>
            <div class="w-709px mt-68px" style="font-size: 16px; font-weight: 400; line-height: 35px; color: #000000">
              赛小狸是景烨网络旗下产品，致力于解决体育赛事和户外活动主办方运营管理需求，为活动组织者提供一站式信息化解决方案。产品主要围绕跑步和户外这两大主线，打造深度垂直社区，实现体育社群和赛事活动的共融。
            </div>
          </div>
          <!-- right -->
          <div class="w-490px h-320px">
            <img class="w-full h-full" src="@/assets/image/guanyu/download.png" alt="" />
          </div>
        </div>
      </div>

      <div class="w-1360px -mt-180px m-auto" style="background-color: #f4f6f9">
        <div class="flex flex-col items-center bg-white">
          <div class="mt-34px text-46px">联系我们</div>
          <div class="mt-30px text-20px" style="color: #666666">拨打下方电话，让我们更早相识吧</div>
          <div class="mt-30px flex justify-between">
            <div class="w-256px h-222px flex flex-col justify-center items-center">
              <div class="w-70px h-70px">
                <img class="w-full h-full" src="@/assets/image/guanyu/address.png" alt="" />
              </div>
              <div class="mt-22px text-22px font-semibold">公司地址</div>

              <div class="mt-18px text-16px text-center" style="color: #666666">
                安徽省合肥市滨湖新区万达银座B座825-826
              </div>
            </div>

            <div class="w-256px h-200px flex flex-col justify-center items-center">
              <img class="w-70px h-70px" src="@/assets/image/guanyu/phone.png" alt="" />
              <div class="mt-22px text-22px font-semibold">联系电话</div>

              <div class="mt-18px text-16px" style="color: #666666">150-0001-7750</div>
            </div>

            <div class="w-256px h-200px flex flex-col justify-center items-center">
              <img class="w-70px h-70px" src="@/assets/image/guanyu/qq.png" alt="" />
              <div class="mt-22px text-22px font-semibold">在线QQ</div>

              <div class="mt-18px text-16px" style="color: #666666">403176253/3177489836</div>
            </div>

            <div class="w-256px h-200px flex flex-col justify-center items-center">
              <img class="w-70px h-70px" src="@/assets/image/guanyu/email.png" alt="" />
              <div class="mt-22px text-22px font-semibold">E-mail</div>

              <div class="mt-18px text-16px" style="color: #666666">milo.ji@jyecloud.cn</div>
            </div>

            <div class="w-256px h-200px flex flex-col justify-center items-center">
              <img class="w-70px h-70px" src="@/assets/image/guanyu/netword.png" alt="" />
              <div class="mt-22px text-22px font-semibold">网址</div>

              <div class="mt-18px text-16px" style="color: #666666">www.jyecloud.cn</div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full h-400px">
        <map-container></map-container>
      </div>
    </div>
  </div>
</template>
<script>
import MapContainer from '@/components/Map/MapContainer.vue'
export default {
  components: { MapContainer },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style scoped lang="less">
.header {
  width: 100%;
  height: 300px;
  background: url('../../assets/image/guanyu/bg.png') no-repeat;
  background-size: 100% 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
