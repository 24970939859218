<template>
  <div id="container">
    <div class="custom-content-marker">
      <img src="//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png" />
      <div class="close-btn" onclick="clearMarker()">X</div>
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
window._AMapSecurityConfig = {
  securityJsCode: 'eef969ef8220dbe998b2deaab47e9eff'
}
export default {
  data() {
    return {
      map: null
    }
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: '1e6691879dc8b61a4a28df8181ba260c', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map('container', {
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 16, //初始化地图级别
            center: [117.304325, 31.705511] //初始化地图中心点位置
          })
          const position = new AMap.LngLat(117.304325, 31.705511) // Marker经纬度
          const marker = new AMap.Marker({
            position: position,
            icon: 'https://jingye.oss-cn-qingdao.aliyuncs.com/news/1a26b2c729871da602b42ab593bad29.png',
            // label: { content: '222' },
            offset: new AMap.Pixel(-13, -30) // 以 icon 的 [center bottom] 为原点
          })
          this.map.add(marker)

          var text = new AMap.Text({
            position: new AMap.LngLat(117.304325, 31.705511),
            anchor: 'top-left',
            text: '合肥景烨网络科技有限公司',
            style: {
              'background-color': 'rgba(0,0,0,0)',
              'margin-left': '30px',
              'margin-top': '-30px',
              border: '0px',
              'font-size': '26px'
            }
          })
          this.map.add(text)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap()
  }
}
</script>

<style lang="less">
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}
.custom-content-marker {
  position: relative;
  width: 25px;
  height: 34px;
}

.custom-content-marker img {
  width: 100%;
  height: 100%;
}

.custom-content-marker .close-btn {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background: #ccc;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 15px;
  box-shadow: -1px 1px 1px rgba(10, 10, 10, 0.2);
}

.custom-content-marker .close-btn:hover {
  background: #666;
}

@keyframes amap-overlay-text-container {
  0%,
  100% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
@-webkit-keyframes amap-overlay-text-container {
  0%,
  100% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.amap-overlay-text-container {
  -webkit-animation: amap-overlay-text-container 1s infinite;
  animation: amap-overlay-text-container 1s infinite;
}

.amap-icon {
  @keyframes img {
    0%,
    100% {
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  @-webkit-keyframes img {
    0%,
    100% {
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  img {
    -webkit-animation: img 1s infinite;
    animation: img 1s infinite;
  }
}
</style>
